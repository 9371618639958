<template>
    <div>
        <BT-Fast-Start-Banner />
        <BT-Blade-Items
            addBladeName="customer"
            bladeName="customers"
            :bladesData="bladesData"
            :bladesToClear="bladesToClear"
            canSearchLocal
            canShowInactive
            :getParams="item => { return { includeDetails: false, templateID: item.data == null ? null : item.data.templateID } }"
            :getSelectBladeData="getSelectBladeData"
            :headers="[
                { text: 'Company Name', value: 'buyer.companyName', title: 1, csv: true, searchable: true },
                { text: 'Can Order', value: 'isCustomerOrderingEnabled', bool: true },
                { text: 'Product Group', value: 'productOfferingGroupID', navigation: 'product-groups', itemText: 'groupName', hide: true },
                { text: 'Payment Terms', value: 'paymentTermsID', navigation: 'supplier-payment-terms', itemText: 'paymentTermsName', hide: true },
                { text: 'Price Tier', value: 'priceTierID', navigation: 'supplier-price-tiers', itemText: 'priceTierName', hide: true },
                { text: 'Ordering Arrangement', value: 'orderingArrangement', hide: true },
                { text: 'Automatic Lines', value: 'automaticLineItemGroupID', navigation: 'automatic-line-item-groups', itemText: 'groupName', hide: true }]"
            :itemProperties="['ID', 'BuyerID', 'Buyer', 'OrderingArrangement', 'IsCustomerOrderingEnabled', 'ProductOfferingGroupID', 'AutomaticLineItemGroupID', 'PaymentTermsID', 'PriceTierID']"
            minimizeOnSelect
            navigation="customers"
            :onSelect="onSelect"
            syncNavigation="customer-syncing"
            title="Customers"
            useServerPagination
            :defaultBladeWidth="350">
            <template slot="settings">
                <v-list-item :to="{ name: 'customer-requests' }">
                    <v-list-item-icon>
                        <v-icon small>mdi-account-question</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-subtitle>New Requests</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item :to="{ name: 'customers-board' }">
                    <v-list-item-icon>
                        <v-icon small>mdi-view-dashboard-variant-outline</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-subtitle>Board</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item :to="{ name: 'order-slot-grouper' }">
                    <v-list-item-icon>
                        <v-icon small>mdi-timer</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-subtitle>Order Slot Grouper</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item :to="{ name: 'customer-locations' }">
                    <v-list-item-icon>
                        <v-icon small>mdi-map-marker</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-subtitle>Customer Locations</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
                <v-divider />
                <v-subheader>Item Actions</v-subheader>
                <v-list-item @click="showGroups = !showGroups">
                    <v-list-item-icon><v-icon small :class="showGroups ? 'success--text' : ''">mdi-account-group</v-icon></v-list-item-icon>
                    <v-list-item-content><v-list-item-subtitle>{{ showGroups ? 'Hide' : 'Show' }} Groups</v-list-item-subtitle></v-list-item-content>
                </v-list-item>
                <v-list-item @click="showSlots = !showSlots">
                    <v-list-item-icon><v-icon small :class="showSlots ? 'success--text' : ''">mdi-timeline-clock</v-icon></v-list-item-icon>
                    <v-list-item-content><v-list-item-subtitle>{{ showSlots ? 'Hide' : 'Show' }} Order Slots</v-list-item-subtitle></v-list-item-content>
                </v-list-item>
                <!-- <v-list-item @click="showLive = !showLive">
                    <v-list-item-icon><v-icon small :class="showLive ? 'success--text' : ''">mdi-handshake</v-icon></v-list-item-icon>
                    <v-list-item-content><v-list-item-subtitle>{{ showLive ? 'Hide' : 'Show' }} Live Accounts</v-list-item-subtitle></v-list-item-content>
                </v-list-item> -->
                <v-list-item @click="showJourneys = !showJourneys">
                    <v-list-item-icon><v-icon small :class="showJourneys ? 'success--text' : ''">mdi-truck</v-icon></v-list-item-icon>
                    <v-list-item-content><v-list-item-subtitle>{{ showJourneys ? 'Hide' : 'Show' }} Journeys</v-list-item-subtitle></v-list-item-content>
                </v-list-item>
                <v-divider />
                <v-expansion-panels>
                    <v-expansion-panel>
                        <v-expansion-panel-header>Settings</v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <BT-Entity
                                v-if="$canView('supplier-settings')"
                                ignoreID
                                inline
                                navigation="supplier-settings"
                                single>
                                <template v-slot="{ item, save }">
                                    <!-- <v-divider />
                                    <v-subheader>Settings</v-subheader> -->
                                    <v-list-item>
                                        <v-list-item-content>
                                            <v-list-item-subtitle>Publically Visible</v-list-item-subtitle>
                                        </v-list-item-content>
                                        <v-list-item-action>
                                            <v-switch v-model="item.isPublicSupplier" color="success" @change="save" />
                                        </v-list-item-action>
                                    </v-list-item>
                                    <v-list-item>
                                        <v-list-item-content>
                                            <v-list-item-subtitle>Public Description</v-list-item-subtitle>
                                        </v-list-item-content>
                                        <v-list-item-action>
                                            <v-edit-dialog>
                                                <v-icon small :class="item.publicDescription == null || item.publicDescription.length == 0 ? '' : 'success--text'">mdi-book-open-variant</v-icon>
                                                <template v-slot:input>
                                                    <v-textarea
                                                        clearable
                                                        outlined
                                                        label="Public Description"
                                                        v-model="item.publicDescription"
                                                        @change="save" />
                                                </template>
                                            </v-edit-dialog>
                                        </v-list-item-action>
                                    </v-list-item>
                                    <v-list-item>
                                        <v-list-item-content>
                                            <v-list-item-subtitle>Open To New Customers</v-list-item-subtitle>
                                        </v-list-item-content>
                                        <v-list-item-action>
                                            <v-switch v-model="item.isOpenToAgreementRequests" color="success" @change="save" />
                                        </v-list-item-action>
                                    </v-list-item>
                                    <v-list-item>
                                        <v-list-item-content>
                                            <v-list-item-subtitle>Auto Accept New Customers</v-list-item-subtitle>
                                        </v-list-item-content>
                                        <v-list-item-action>
                                            <v-switch v-model="item.autoApproveAgreementRequests" color="success" @change="save" />
                                        </v-list-item-action>
                                    </v-list-item>
                                    <v-list-item v-if="$canView('customer-templates')">
                                        <v-list-item-content>
                                            <v-list-item-subtitle>Default Template For New Customers</v-list-item-subtitle>
                                        </v-list-item-content>
                                        <v-list-item-action>
                                            <BT-Menu-Select
                                                icon="mdi-account-box"
                                                itemText="templateName"
                                                itemValue="id"
                                                navigation="customer-templates"
                                                v-model="item.defaultTemplateID"
                                                @change="save" />
                                        </v-list-item-action>
                                    </v-list-item>
                                </template>
                            </BT-Entity>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
                
            </template>
            <template v-slot:toolbar-right-far>
                <BT-Menu
                    
                    hideHeader
                    icon="mdi-plus"
                    small>
                    <v-list-item :to="{ name: 'customer', params: { id: 'new' } }">
                        <v-list-item-content>
                            <v-list-item-title>New Customer</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item :to="{ name: 'new-customers' }">
                        <v-list-item-content>
                            <v-list-item-title>Muliple Customers</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </BT-Menu>
            </template>
            <template v-slot:actionsRight>
                <v-btn
                    small
                    icon
                    @click="integrationToggle = !integrationToggle">
                    <v-icon small>mdi-connection</v-icon>
                </v-btn>
            </template>
            <template v-slot:bottom>
                <BT-Sidebar-External-Integration
                    navigation="customers"
                    :onPullNewItems="newCustomers"
                    :showToggle="integrationToggle"
                    sidebarLabel="Sync With"
                    :startOpen="integrationToggle"
                    syncNavigation="customer-syncing">
                    <template v-slot:listItem="{ item }">
                        <v-list-item-title>{{ item.buyer.companyName }}</v-list-item-title>
                    </template>
                </BT-Sidebar-External-Integration>
            </template>
            <template v-slot:itemActions="{ item }">
                <v-row dense>
                    <v-spacer />
                    <v-btn
                        v-if="isSalesHub"
                        @click.stop="selectCustomer(item)"
                        icon
                        small
                        title="New Order">
                        <v-icon>mdi-plus</v-icon>
                    </v-btn>

                    <BT-Sidebar-List
                        v-if="showGroups"
                        inline
                        leftIcon="mdi-account-group"
                        label="Customer Groups"
                        linkGetURL="/get/Links?SupplyAgreementID={id}"
                        linkNavigation="customer-groups"
                        manyText="groupName"
                        manyProp="supplyAgreementGroupID"
                        manyToMany
                        manyNavigation="customer-groups"
                        :searchProps="['groupName']"
                        :singleID="item.id"
                        singleProp="supplyAgreementID"
                        small
                        title="Customer Groups" />

                    <BT-Sidebar-List
                        v-if="showSlots && item.orderingArrangement == 'Slotted'"
                        inline
                        :itemProperties="['ID','SlotName','NextAttentionDate']"
                        leftIcon="mdi-timeline-clock"
                        label="Order Slots"
                        linkGetURL="/get/Links?AgreementID={id}"
                        linkNavigation="customers"
                        manyText="slotName"
                        manyProp="orderSlotID"
                        manyToMany
                        manyNavigation="order-slots"
                        :searchProps="['slotName']"
                        :singleID="item.id"
                        singleProp="supplyAgreementID"
                        small
                        sortProp="slotName"
                        temporary
                        title="Order Slots" />

                    <BT-Sidebar-Items
                        v-if="showJourneys"
                        :canSearch="false"
                        leftIcon="mdi-truck"
                        inline
                        :itemProperties="['ID','JourneyName']"
                        label="Journeys"
                        navigation="journey-templates"
                        :searchProps="['journeyTemplate.journeyName']"
                        :onPullSuccessAsync="(list, refresh) => pullPointers(list, refresh, item)"
                        :params="{ includeDetails: false, buyerID: item.buyerID }"
                        small
                        :trackChanges="false">
                        <template v-slot="{ item }">
                            <v-list-item-action>
                                <v-row>
                                    <v-btn v-if="item.id == null" small icon @click.stop="savePointer(item)" :loading="item.isLoading">
                                        <v-icon small class="error--text">mdi-close</v-icon>
                                    </v-btn>
                                    <v-btn v-else-if="$BlitzIt.tracker.isChanged(item)" small icon @click.stop="savePointer(item)" :loading="item.isLoading">
                                        <v-icon small>mdi-content-save</v-icon>
                                    </v-btn>
                                    <v-btn v-else small icon @click.stop="deletePointer(item)" :loading="item.isLoading">
                                        <v-icon small class="success--text">mdi-check</v-icon>
                                    </v-btn>
                                </v-row>
                            </v-list-item-action>
                            <v-list-item-content>
                                <v-list-item-title>{{ item.journeyTemplate.journeyName }}</v-list-item-title>
                                <v-list-item-subtitle>{{ item.location | toLocationLine }}</v-list-item-subtitle>
                                <v-list-item-subtitle v-if="item.id != null">
                                    <BT-Chips-Menu
                                        v-model="item.weekdays"
                                        column
                                        isEditing
                                        :items="weekdayOptions()"
                                        multiple
                                        returnCSV
                                        xSmall />
                                </v-list-item-subtitle>
                            </v-list-item-content>
                        </template>
                    </BT-Sidebar-Items>

                    <v-icon v-if="item.buyer != null && item.buyer.isManagedByAnotherAccount" small title="Not a live account.">mdi-robot</v-icon>
                    <v-icon v-else small title="A live account.">mdi-account</v-icon>
                    <!-- <Customer-Link
                        v-if="showLive && item.buyer != null && item.buyer.isManagedByAnotherAccount"
                        :agreement="item"
                        :managedAccountID="item != null ? item.buyer.id : null" />
                    <v-icon 
                        v-else-if="showLive"
                        small
                        title="Live Account">mdi-account</v-icon> -->
                </v-row>
            </template>
        </BT-Blade-Items>
    </div>
</template>

<script>
import { getStreetNumber, getStreetName } from '~helpers';

export default {
    name: 'Customers-Blade',
    components: {
        // CustomerLink: () => import('~home/customers/Customer-Link.vue'),
        BTChipsMenu: () => import('~components/BT-Chips-Menu.vue'),
        BTFastStartBanner: () => import('~components/BT-Fast-Start-Banner.vue'),
        BTMenu: () => import('~components/BT-Menu.vue'),
        BTMenuSelect: () => import('~components/BT-Menu-Select.vue'),
        BTSidebarExternalIntegration: () => import('~components/BT-Sidebar-External-Integration.vue'),
        BTSidebarItems: () => import('~components/BT-Sidebar-Items.vue'),
        BTSidebarList: () => import('~components/BT-Sidebar-List.vue')
    },
    data: function() {
        return {
            bladesToClear: [
                { bladeName: 'customer-order' },
                { bladeName: 'stock-consignment' },
                { bladeName: 'customer-consignment' },
                { bladeName: 'courier-order' }
            ],
            integrationToggle: false,
            showGroups: false,
            showJourneys: false,
            // showLive: false,
            showSlots: false,
            //weekdayOptions: ['Always', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
        }
    },
    props: {
        bladesData: {
            type: Object,
            default: null
        },
        isCustomerHub: {
            type: Boolean,
            default: false
        },
        isSalesHub: {
            type: Boolean,
            default: false
        },
        onSelect: {
            type: Function,
            default: null
        }
    },
    mounted() {
        var q = this.$route.query;
        if (q) {
            if (q.integrate != null) {
                this.integrationToggle = !this.integrationToggle;
            }
        }
    },
    methods: {
        selectCustomer(item) {
            this.bladesData.openBlade({ bladeName: 'customer-order', clearBlades: false, data: { customerID: item.buyerID, id: 'new', agreementID: item.id, customer: item } });
        },
        getSelectBladeData(bladeData, customer) {
            if (customer != null) {
                return { id: customer.id, agreementID: customer.id, customerID: customer.buyerID, customer: customer };
            }
            return {};
        },

        async deletePointer(pointer) {
            try {
                pointer.isLoading = true;
                await this.$BlitzIt.store.delete('journey-pointers', pointer.id, null);
                pointer.id = null;
                pointer.rowVersion = null;
                this.$BlitzIt.tracker.reset(pointer);
            }
            catch (err) {
                this.msg = this.extractErrorDescription(err);
            }
            finally {
                pointer.isLoading = false;
            }
        },
        fromShopify(extItem) {
            console.log(extItem);
            console.log('b');

            var nItem = {
                companyName: null,
                addressLineOne: null,
                streetNumber: null,
                streetName: null,
                suburb: null,
                state: null,
                postcode: null,
                email: extItem.email,
                phoneNumber: extItem.phone,
                shopifyID: extItem.id.toString()
            };

            if (extItem.defaultAddress != null && extItem.defaultAddress.company != null) {
                nItem.companyName = extItem.defaultAddress.company;
            }
            else if (extItem.firstName != null && extItem.lastName != null) {
                nItem.companyName = extItem.firstName + ' ' + extItem.lastName;
            }
            else if (extItem.lastName != null) {
                nItem.companyName = extItem.lastName;
            }
            else if (extItem.firstName != null) {
                nItem.companyName = extItem.firstName;
            }

            var address = null;

            if (extItem.defaultAddress != null) {
                address = extItem.defaultAddress;
            }
            else if (this.isLengthyArray(extItem.addresses)) {
                address = extItem.addresses[0];
            }

            if (address != null) {
                nItem.state = address.province;
                nItem.postcode = address.zip;
                nItem.suburb = address.city;

                if (address.address2) {
                    nItem.streetNumber = getStreetNumber(address.address2);
                    nItem.streetName = getStreetName(address.address2);
                    
                    if (address.address1) {
                        nItem.addressLineOne = address.address1;
                    }
                }
                else if (address.address1) {
                    nItem.streetNumber = getStreetNumber(address.address1);
                    nItem.streetName = getStreetName(address.address1);
                }
            }

            if (nItem.phoneNumber == null) {
                if (address != null && address.phone != null) {
                    nItem.phoneNumber = address.phone;
                }
            }
            
            return nItem;
        },
        fromXero(extItem) {
            var nItem = {
                companyName: extItem.name,
                addressLineOne: null,
                streetNumber: null,
                streetName: null,
                suburb: null,
                state: null,
                postcode: null,
                email: extItem.emailAddress ? extItem.emailAddress : null,
                phoneNumber: null,
                xeroID: extItem.contactID
            };

            var address = null;

            if (extItem.addresses && extItem.addresses.length > 0) {
                var streetAddress = extItem.addresses.find(x => x.addressType === 'STREET');
                var poAddress = extItem.addresses.find(x => x.addressType === 'POBOX');
                
                if (poAddress && !streetAddress?.City && !streetAddress?.postalCode && !streetAddress?.region) {
                    address = poAddress;
                }
                else if (streetAddress) {
                    address = streetAddress;
                }

                if (!address && extItem.addresses.length > 0) {
                    address = extItem.addresses[0];
                }
            }

            if (address != null) {
                if (address.emailAddress) {
                    nItem.email = address.emailAddress;
                }
                if (address.region) {
                    nItem.state = address.region;
                }
                if (address.postalCode) {
                    nItem.postcode = address.postalCode;
                }
                if (address.city) {
                    nItem.suburb = address.city;
                }
                if (address.addressLine2) {
                    nItem.streetNumber = getStreetNumber(address.addressLine2);
                    nItem.streetName = getStreetName(address.addressLine2);
                    
                    if (address.addressLine1) {
                        nItem.addressLineOne = address.addressLine1;
                    }
                }
                else if (address.addressLine1) {
                    nItem.streetNumber = getStreetNumber(address.addressLine1);
                    nItem.streetName = getStreetName(address.addressLine1);
                }
            }

            if (extItem.phones && extItem.phones.length > 0) {
                var phoneNumber = extItem.phones.find(x => x.phoneType === 'DEFAULT' && x.phoneNumber);

                if (!phoneNumber) {
                    phoneNumber = extItem.phones.find(x => x.phoneType === 'MOBILE' && x.phoneNumber);
                }

                if (!phoneNumber) {
                    phoneNumber = extItem.phones[0];
                }
                
                var a = phoneNumber.phoneAreaCode ? phoneNumber.phoneAreaCode : '';
                var b = phoneNumber.phoneNumber ? phoneNumber.phoneNumber : '';
                nItem.phoneNumber = a + b;
            }

            return nItem;
        },
        newCustomers(extList, partyID) {
            var newCustList = [];

            for (let i = 0; i < extList.length; i++) {
                const extItem = extList[i].externalItem;
                var nItem = null;
                if (partyID == 'xero') {
                    nItem = this.fromXero(extItem);
                }
                else if (partyID == 'shopify') {
                    nItem = this.fromShopify(extItem);
                }

                if (nItem != null) {
                    newCustList.push(nItem);
                }
            }

            this.$router.push({ name: 'new-customers', params: { customers: newCustList } });
        },
        async savePointer(pointer) {
            try {
                pointer.isLoading = true;
                var res = null;
                if (pointer.id == null) {
                    res = await this.$BlitzIt.store.post('journey-pointers', pointer, null, null);
                }
                else {
                    res = await this.$BlitzIt.store.patch('journey-pointers', pointer, null);
                }
                
                pointer.id = res.id;

                pointer.rowVersion = res.rowVersion;
                this.$BlitzIt.tracker.reset(pointer);
            }
            catch(err) {
                this.msg = this.extractErrorDescription(err);
            }
            finally {
                pointer.isLoading = false;
            }
            
        },
        async pullPointers(templates, refresh, agr) {
            var locationRes = await this.$BlitzIt.store.get('suppliers', agr.id, null, refresh, null, '/Locations/Locations');
            var locations = locationRes.possibleLocations;
            var res = [];

            if (this.isLengthyArray(locations)) {
                var locationIDs = locations.map(x => x.id);

                var pointers = await this.$BlitzIt.store.getAll('journey-pointers', { includeDetails: true, locationIDs: locationIDs.toString()}, refresh, null, null);

                templates.forEach(t => {
                    locations.forEach(l => {
                        var p = pointers.find(p => p.locationID == l.id && p.journeyTemplateID == t.id);
                        if (p == null) {
                            p = {
                                id: null,
                                rowVersion: null,
                                pointerName: null,
                                boundary: null,
                                weekdays: 'Always',
                                journeyTemplateID: t.id,
                                locationID: l.id,
                                location: l
                            };
                        }

                        this.$BlitzIt.tracker.track(p, {
                            trackProps: ['id', 'rowVersion', 'weekdays'],
                            assignIgnored: { journeyTemplate: t, isLoading: false }
                        });

                        res.push(p);
                    });
                });
            }

            return res;
        },
    }
}
</script>